import React, { useState } from 'react'
import { useRenderInterval } from '../hooks/useRenderInterval'
import clsx from 'clsx'

export function SmudgeTimer() {
  useRenderInterval(1000 / 30)
  const [startedAt, setStartedAt] = useState<number | null>(null)

  if (startedAt === null) {
    return (
      <div className='d-flex align-items-center' key='inactive'>
        <button className='btn btn-primary btn-sm ml-auto' onClick={() => setStartedAt(Date.now())}>
          Smudged!
        </button>
      </div>
    )
  }

  const seconds = Math.floor((Date.now() - startedAt) / 1000)

  return (
    <div className='d-flex align-items-center' key='active'>
      <div
        className={clsx(['mr-4', { 'text-muted': seconds < 60 }])}
        style={{ textDecoration: seconds < 60 ? 'line-through' : 'none' }}
      >
        Demon
      </div>

      <div
        className={clsx(['mr-4', { 'text-muted': seconds < 90 }])}
        style={{ textDecoration: seconds < 90 ? 'line-through' : 'none' }}
      >
        Others
      </div>

      <div
        className={clsx(['mr-4', { 'text-muted': seconds < 180 }])}
        style={{ textDecoration: seconds < 180 ? 'line-through' : 'none' }}
      >
        Spirit
      </div>

      <div className='ml-auto'>{seconds}s</div>

      <button className='btn btn-outline-secondary btn-sm ml-4' onClick={() => setStartedAt(null)}>
        Stop timer
      </button>
    </div>
  )
}
