export enum EVIDENCE {
  'EMF Level 5' = 'EMF Level 5',
  'D.O.T.S Projector' = 'D.O.T.S',
  'Fingerprints' = 'Ultraviolet',
  'Ghost Orb' = 'Ghost Orb',
  'Ghost Writing' = 'Writing',
  'Spirit Box' = 'Spirit Box',
  'Freezing Temperatures' = 'Freezing',
}

export interface Ghost {
  name: string
  description: string
  strengths: string
  weaknesses: string
  evidence: [EVIDENCE, EVIDENCE, EVIDENCE]
}

export const GHOSTS: Array<Ghost> = [
  {
    name: 'Spirit',
    description:
      'A spirit is the most common Ghost you will come across however it is still very powerful and dangerous. They are usually discovered at one of their hunting grounds after an unexplained death.',
    strengths: 'Nothing',
    weaknesses: 'Using Smudge Sticks on a Spirit will stop it attacking for a long period of time.',
    evidence: [EVIDENCE['EMF Level 5'], EVIDENCE['Spirit Box'], EVIDENCE['Ghost Writing']],
  },
  {
    name: 'Wraith',
    description:
      'A Wraith is one of the most dangerous Ghosts you will find. It is also the only known Ghost that has the ability of flight and has sometimes been known to travel through walls.',
    strengths: 'Wraiths almost never touch the ground meaning it can’t be tracked by footsteps.',
    weaknesses: 'Wraiths have a toxic reaction to Salt',
    evidence: [EVIDENCE['EMF Level 5'], EVIDENCE['Spirit Box'], EVIDENCE['D.O.T.S Projector']],
  },
  {
    name: 'Phantom',
    description:
      'A Phantom is a Ghost that can possess the living, most commonly summoned by a Ouija Board. It also induces fear into those around it.',
    strengths: 'Looking at a Phantom will considerably drop your sanity.',
    weaknesses: 'Taking a photo of the Phantom will make it temporarily disappear.',
    evidence: [EVIDENCE['Spirit Box'], EVIDENCE['Fingerprints'], EVIDENCE['D.O.T.S Projector']],
  },
  {
    name: 'Poltergeist',
    description:
      'One of the most famous Ghosts, a Poltergeist, also known as a noisy ghost can manipulate objects around it to spread fear into it’s victims.',
    strengths: 'A Poltergeist can throw huge amounts of objects at once.',
    weaknesses: 'A Poltergeist is almost ineffective in an empty room',
    evidence: [EVIDENCE['Spirit Box'], EVIDENCE['Fingerprints'], EVIDENCE['Ghost Writing']],
  },
  {
    name: 'Banshee',
    description:
      'A Banshee is a natural hunter and will attack anything. It has been known to stalk it’s prey one at a time until making it’s kill.',
    strengths: 'A Banshee will only target one person at a time.',
    weaknesses: 'Banshees fear the Crucifix and will be less aggressive when near one.',
    evidence: [EVIDENCE['Fingerprints'], EVIDENCE['Ghost Orb'], EVIDENCE['D.O.T.S Projector']],
  },
  {
    name: 'Jinn',
    description:
      'A Jinn is territorial Ghost that will attack when threatened. It has also been known to be able to travel at significant speed.',
    strengths: 'A Jinn will travel at a faster speed if it’s victim is far away.',
    weaknesses:
      'Turning off the locations power source will prevent the Jinn from using it’s ability',
    evidence: [
      EVIDENCE['EMF Level 5'],
      EVIDENCE['Fingerprints'],
      EVIDENCE['Freezing Temperatures'],
    ],
  },
  {
    name: 'Mare',
    description: 'A Mare is the source of all nightmares, making it most powerful in the dark.',
    strengths: 'A Mare will have an increased chance to attack in the dark.',
    weaknesses: 'Turning the lights on around the Mare will lower it’s chance to attack.',
    evidence: [EVIDENCE['Spirit Box'], EVIDENCE['Ghost Orb'], EVIDENCE['Ghost Writing']],
  },
  {
    name: 'Revenant',
    description:
      'A Revenant is a slow but violent Ghost that will attack indiscriminately. It has been rumoured to travel at a significantly high speed when hunting.',
    strengths: 'A Revenant will travel at a significantly faster speed when hunting a victim.',
    weaknesses: 'Hiding from the Revenant will cause it to move very slowly.',
    evidence: [EVIDENCE['Ghost Orb'], EVIDENCE['Ghost Writing'], EVIDENCE['Freezing Temperatures']],
  },
  {
    name: 'Shade',
    description:
      'A Shade is known to be a Shy Ghost. There is evidence that a Shade will stop all paranormal activity if there are multiple people nearby.',
    strengths: 'Being shy means the Ghost will be harder to find',
    weaknesses: 'The Ghost will not enter hunting mode if there is multiple people nearby.',
    evidence: [
      EVIDENCE['EMF Level 5'],
      EVIDENCE['Ghost Writing'],
      EVIDENCE['Freezing Temperatures'],
    ],
  },
  {
    name: 'Demon',
    description:
      'A Demon is one of the worst Ghosts you can encounter. It has been known to attack without a reason.',
    strengths: 'Demons will attack more often then any other Ghost.',
    weaknesses:
      'Asking a Demon successful questions on the Ouija Board won’t lower the users sanity.',
    evidence: [
      EVIDENCE['Fingerprints'],
      EVIDENCE['Ghost Writing'],
      EVIDENCE['Freezing Temperatures'],
    ],
  },
  {
    name: 'Yurei',
    description:
      'A Yurei is a Ghost that has returned to the physical world, usually for the purpose of revenge or hatred',
    strengths: 'Yurei’s have been known to have a stronger effect on people’s sanity.',
    weaknesses:
      'Smudging the Yurei’s room will cause it to not wander around the location for a long time.',
    evidence: [
      EVIDENCE['Ghost Orb'],
      EVIDENCE['Freezing Temperatures'],
      EVIDENCE['D.O.T.S Projector'],
    ],
  },
  {
    name: 'Oni',
    description:
      'Oni’s are a cousin to the Demon and possess the extreme strength. There have been rumours that they become more active around their prey.',
    strengths:
      'Oni’s are more active when people are nearby and have been seen moving objects at great speed.',
    weaknesses: 'Being more active will make the Oni easier to find and identify.',
    evidence: [
      EVIDENCE['EMF Level 5'],
      EVIDENCE['Freezing Temperatures'],
      EVIDENCE['D.O.T.S Projector'],
    ],
  },
  {
    name: 'Hantu',
    description:
      'A Hantu is a rare ghost that can be found in hot climates. They are known to attack often during cold weather.',
    strengths: 'Lower temperatures can cause the Hantu to move at faster speeds.',
    weaknesses: 'A Hantu will move slower in warmer areas.',
    evidence: [EVIDENCE['Fingerprints'], EVIDENCE['Ghost Orb'], EVIDENCE['Freezing Temperatures']],
  },
  {
    name: 'Yokai',
    description:
      'A Yokai is a common type of ghost that is attracted to human voices. They can usually be found haunting family homes.',
    strengths: "Talking near a Yokai will anger it and increase it's chance of attacking.",
    weaknesses: 'When hunting a Yokai can only hear voices close to it.',
    evidence: [EVIDENCE['Spirit Box'], EVIDENCE['Ghost Orb'], EVIDENCE['D.O.T.S Projector']],
  },
  {
    name: 'Goryo',
    description:
      'Using a video camera is the only way to view a Goryo, when it passes through a DOTS projector.',
    strengths: 'A Goryo will usually only show itself on camera if there are no people nearby.',
    weaknesses: 'They are rarely seen far from their place of death.',
    evidence: [EVIDENCE['EMF Level 5'], EVIDENCE['Fingerprints'], EVIDENCE['D.O.T.S Projector']],
  },
  {
    name: 'Myling',
    description:
      'A Myling is a very vocal and active ghost. They are rumoured to be quiet when hunting their prey.',
    strengths: 'A Myling is known to be quieter when hunting.',
    weaknesses: 'Mylings more frequently make paranormal sounds.',
    evidence: [EVIDENCE['EMF Level 5'], EVIDENCE['Fingerprints'], EVIDENCE['Ghost Writing']],
  },
  {
    name: 'Onryo',
    description: `The Onryo is often referred to as "The Wrathful Spirit". It steals souls from dying victims' bodies to seek revenge. This ghost has been known to fear any form of fire, and will do anything to be farm from it.`,
    strengths: 'Extinguishing a flame can cause an Onryo to attack.',
    weaknesses: 'When threatened, this ghost will be less likely to hunt.',
    evidence: [EVIDENCE['Spirit Box'], EVIDENCE['Ghost Orb'], EVIDENCE['Freezing Temperatures']],
  },
  {
    name: 'The Twins',
    description: `These ghosts have been reported to mimic each other's actions. They alternate their attacks to confuse their prey.`,
    strengths: 'Either Twin can be angered and initiate an attack on their prey.',
    weaknesses: 'The Twins will often interact with the environment at the same time.',
    evidence: [EVIDENCE['EMF Level 5'], EVIDENCE['Spirit Box'], EVIDENCE['Freezing Temperatures']],
  },
  {
    name: 'Raiju',
    description: `A Raiju is a demon that thrives on electrical current. While generally calm, they can become agitated when overwhelmed with power.`,
    strengths: 'A Raiju can siphon power from nearby electrical devices, making it move faster.',
    weaknesses:
      'Raiju are constantly disrupting electronic equipment, making it easier to track when attacking.',
    evidence: [EVIDENCE['EMF Level 5'], EVIDENCE['Ghost Orb'], EVIDENCE['D.O.T.S Projector']],
  },
  {
    name: 'Obake',
    description: `Obake are terrifying shape-shifters, capable of taking on many forms. They have been seen taking on humanoid shapes to attract their prey.`,
    strengths: 'When interacting with the environment, an Obake will rarely leave a trace.',
    weaknesses: 'Sometimes this ghost will shapeshift, leaving behind unique evidence.',
    evidence: [EVIDENCE['EMF Level 5'], EVIDENCE['Fingerprints'], EVIDENCE['Ghost Orb']],
  },
  {
    name: 'The Mimic',
    description: `The Mimic is an elusive, mysterious, copycat ghost that mirrors traits and behaviors from others, including other ghost types.`,
    strengths: "We're unsure what this ghost is capable of. Be careful.",
    weaknesses: 'Several reports have noted ghost orb sightings near mimics.',
    evidence: [EVIDENCE['Spirit Box'], EVIDENCE['Fingerprints'], EVIDENCE['Freezing Temperatures']],
  },
  {
    name: 'Moroi',
    description: `Moroi have risen from the grave to drain energy from the living. They have been known to place curses on their victims, curable only by antidotes or moving very far away.`,
    strengths: 'The weaker their victims, the stronger the Moroi becomes.',
    weaknesses: 'Moroi suffer from hyperosmia, weakening them for longer periods.',
    evidence: [
      EVIDENCE['Spirit Box'],
      EVIDENCE['Ghost Writing'],
      EVIDENCE['Freezing Temperatures'],
    ],
  },
  {
    name: 'Deogen',
    description: `Sometimes surrounded by an endless fog, Deogen have been eluding ghost hunters for years. These ghosts have been reported to find even the most hidden prey, before stalking them into exhaustion.`,
    strengths: "Deogen constantly sense the living. You can run but you can't hide",
    weaknesses: 'Deogen require a lot of energy to form and will move very slowly.',
    evidence: [EVIDENCE['Spirit Box'], EVIDENCE['Ghost Writing'], EVIDENCE['D.O.T.S Projector']],
  },
  {
    name: 'Thaye',
    description: `Thaye have been known to rapidly age over time, even in the afterlife. From what we've learned, they seem to deteriorate faster while within the presence of the living.`,
    strengths: 'Upon entering the location, Thaye will become active, defensive and agile.',
    weaknesses: 'Thaye will weaken over time making them weaker, slower and less aggressive.',
    evidence: [EVIDENCE['Ghost Orb'], EVIDENCE['Ghost Writing'], EVIDENCE['D.O.T.S Projector']],
  },
]
