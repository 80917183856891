import React, { ReactNode } from 'react'
import c from 'clsx'
import { EvidenceState } from './Application'

export interface GhostEvidenceBadgeProps {
  /** Content rendered in the badge */
  children: ReactNode

  /** What state the badge is in */
  state: EvidenceState

  /** Any additional CSS classes */
  className?: string
}

export const GhostEvidenceBadge = (pProps: GhostEvidenceBadgeProps) => {
  const props = { className: '', ...pProps }

  return (
    <div
      className={c([
        'cy-GhostEvidenceBadge',
        'badge',
        { 'badge-secondary': props.state === 'MAYBE' },
        { 'badge-success': props.state === 'YES' },
        { 'badge-danger': props.state === 'NO' },
        props.className,
      ])}
    >
      {props.children}
    </div>
  )
}
