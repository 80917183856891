import React, { ReactNode } from 'react'
import c from 'clsx'

export interface EvidenceStateButtonProps {
  /** Wether the button is active */
  active: boolean

  /** On-Click callback */
  onClick: () => void

  /** Content rendered in the button */
  children: ReactNode

  /** The class to render when the button is active */
  activeClassName: string

  /** Any additional CSS classes */
  className?: string
}

export const EvidenceStateButton = (pProps: EvidenceStateButtonProps) => {
  const props = { className: '', ...pProps }

  return (
    <button
      className={c([
        'cy-EvidenceStateButton',
        'btn',
        { 'btn-outline-secondary': !props.active },
        { [props.activeClassName]: props.active },
        'btn-sm',
        props.className,
      ])}
      onClick={() => props.onClick()}
    >
      {props.children}
    </button>
  )
}
