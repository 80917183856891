import React, { useState } from 'react'
import c from 'clsx'
import { GHOSTS, EVIDENCE, Ghost } from '../static/game'
import { EvidenceStateButton } from './EvidenceStateButton'
import { GhostEvidenceBadge } from './GhostEvidenceBadge'
import { SmudgeTimer } from './SmudgeTimer'

export type EvidenceState = 'YES' | 'MAYBE' | 'NO'

let locationEvidenceInitState: Record<string, EvidenceState> = {}
Object.values(EVIDENCE).forEach((x) => {
  locationEvidenceInitState[x] = 'MAYBE'
})

export const Application = () => {
  const [compact, setCompact] = useState(false)
  const [locationEvidence, setLocationEvidence] = useState(locationEvidenceInitState)
  const [markGhosts, setMarkGhosts] = useState<Record<string, boolean>>({})

  function changeLocationEvidence(evidence: EVIDENCE, state: EvidenceState) {
    setLocationEvidence((prevState) => ({ ...prevState, [evidence]: state }))
  }

  function resetLocationEvidence() {
    setLocationEvidence(locationEvidenceInitState)
  }

  function toggleMarkGhost(name: string) {
    setMarkGhosts((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const filteredGhosts = GHOSTS.filter((ghost) =>
    ghostMatchesEvidence(ghost, locationEvidence)
  ).sort((a, b) => (markGhosts[a.name] ? 1 : 0) - (markGhosts[b.name] ? 1 : 0))

  return (
    <div className='d-flex flex-column h-100'>
      <div className='bg-dark p-3 flex-shrink-0 border-bottom border-secondary'>
        <SmudgeTimer />
      </div>

      <div className='flex-grow-1 overflow-auto bg-grey-dark' id='scollbar'>
        {filteredGhosts.map((ghost) => (
          <div
            key={ghost.name}
            className='card m-2 bg-transparent border-secondary'
            onClick={() => toggleMarkGhost(ghost.name)}
            style={markGhosts[ghost.name] ? { textDecoration: 'line-through', opacity: 0.3 } : {}}
          >
            <div className={`card-body px-3 ${compact ? 'py-2' : 'py-3'}`}>
              <h5 className='card-title mb-1'>{ghost.name}</h5>

              <div className='mb-1'>
                {ghost.evidence
                  .sort((a, b) => a.localeCompare(b))
                  .map((ghostEvidence, i) => (
                    <GhostEvidenceBadge
                      key={ghostEvidence}
                      state={locationEvidence[ghostEvidence]}
                      className={c([{ 'mr-1': i !== ghost.evidence.length - 1 }])}
                    >
                      {ghostEvidence}
                    </GhostEvidenceBadge>
                  ))}
              </div>

              {!compact && !markGhosts[ghost.name] && (
                <div>
                  <strong>Strengths: </strong>
                  {ghost.strengths}
                </div>
              )}

              {!compact && !markGhosts[ghost.name] && (
                <div>
                  <strong>Weaknesses: </strong>
                  {ghost.weaknesses}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className='bg-dark p-3 flex-shrink-0 border-top border-secondary'>
        <div className='d-flex align-items-center'>
          <button
            className={c([
              'btn btn-sm mr-2',
              { 'btn-primary': compact },
              { 'btn-outline-secondary': !compact },
            ])}
            onClick={() => setCompact((prev) => !prev)}
          >
            Compact
          </button>

          <button
            className='btn btn-outline-secondary btn-sm ml-auto'
            onClick={() => setMarkGhosts({})}
          >
            Reset marks
          </button>

          <button
            className='btn btn-outline-secondary btn-sm ml-2'
            onClick={() => resetLocationEvidence()}
          >
            Reset all evidence
          </button>
        </div>

        <div
          className='mt-3'
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0.5rem' }}
        >
          {Object.values(EVIDENCE).map((evidence) => (
            <div
              key={evidence}
              className='d-flex align-items-center'
              style={{
                opacity: evidenceMatchesGhosts(evidence, filteredGhosts) ? 1 : 0.3,
                fontSize: '0.8203125rem',
              }}
            >
              <div>{evidence}</div>
              <EvidenceStateButton
                active={locationEvidence[evidence] === 'YES'}
                onClick={() => changeLocationEvidence(evidence, 'YES')}
                activeClassName='btn-success'
                className='ml-auto mr-1'
              >
                Yes
              </EvidenceStateButton>
              <EvidenceStateButton
                active={locationEvidence[evidence] === 'NO'}
                onClick={() => changeLocationEvidence(evidence, 'NO')}
                activeClassName='btn-danger'
              >
                No
              </EvidenceStateButton>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function ghostMatchesEvidence(ghost: Ghost, evidence: Record<string, string>) {
  for (let i = 0; i !== Object.keys(evidence).length; i++) {
    const key = Object.keys(evidence)[i] as EVIDENCE

    if (evidence[key] === 'YES' && !ghost.evidence.includes(key)) {
      return false
    }

    if (evidence[key] === 'NO' && ghost.evidence.includes(key)) {
      return false
    }
  }

  return true
}

function evidenceMatchesGhosts(evidence: EVIDENCE, ghosts: Array<Ghost>) {
  for (let i = 0; i !== ghosts.length; i++) {
    const ghost = ghosts[i]

    if (ghost.evidence.includes(evidence)) {
      return true
    }
  }

  return false
}
