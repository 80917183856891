import { useEffect, useState } from 'react'

/** Re-renders the component at the specified interval. */
export function useRenderInterval(ms: number, active = true) {
  const [, setState] = useState(0)

  useEffect(() => {
    if (!active) return

    const interval = setInterval(() => {
      setState(Math.random())
    }, ms)

    return () => clearInterval(interval)
  }, [ms, active])
}